.desktop-navigation-div {
    background-color: rgba(255, 255, 255, 0.836);
    box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem, rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem;
    border: 0.0625rem solid #dee2e6;
    border-radius: 5rem;
    backdrop-filter: blur(20px) saturate(1.7);
}
.desktop-navigation-nav-link:hover {
    background-color: rgba(6, 100, 240, 0.836);
    color: white;
    cursor: pointer;
    border-radius: 2rem;
    backdrop-filter: blur(20px) saturate(1.7);
}

.desktop-navigation-nav-link span {
    font-size: 1.25rem;
}