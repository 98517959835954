html {
    overflow-y: scroll;
    margin-right: 0px !important;
}

body {
    margin: 0;
    margin-right: 0px !important;
}

.classes.link > a {
    font-size: 1.5rem;
}